import React, { FC } from 'react';

import { Sections } from '@/components/sections/Section';
import { GroupSection } from '@/components/sections/group/Group';
import { Groups, Wrapper } from './styles';
import { ContentfulSection } from '../types.d';
import Reveal from '@/components/reveal/Reveal';

export interface FooterSection extends ContentfulSection {
  columns?: GroupSection[];
}

/**
 * Footer section
 */
const Footer: FC<FooterSection> = ({ columns = [] }) => (
  <Wrapper>
    <div className="container">
      <Groups>
        <Sections ignoreContainer sections={columns} />
      </Groups>
      <Reveal className="social-media footer-container">
        <div className="linkedin">
          <a
            tabIndex={0}
            className="link alt"
            href="https://www.linkedin.com/company/ionacommerce/"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>
        </div>

        <div className="instagram">
          <a
            tabIndex={0}
            className="link alt"
            href="https://www.instagram.com/ionacommerce/"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
        </div>
      </Reveal>
    </div>
  </Wrapper>
);

export default Footer;
